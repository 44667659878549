.agendaArea {
    background: transparent;
    color: white;
    border: 2px solid #4C4C7E;
    border-radius: 1rem;
    padding: 0.5rem;
}

.photo-container{
    position: relative;
    opacity: 1;
    aspect-ratio: 1/1;
}

.photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.photo-commands{
    opacity: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.ranking-item-animated {
    animation-duration: 1s;
    animation-name: fadein;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.photo-container:hover {
    .photo-commands {
        transition: opacity 0.2s ease-in-out;
        opacity: 1 !important;
    }
}

.image-enter {
    opacity: 0;
    transform: scale(0.9);
}

.image-enter-active {
    opacity: 1;
    transform: scale(1.5);
    transition: opacity 200ms, transform 400ms;
}

.image-exit {
    opacity: 1;
    transform: scale(0.9);
}

.image-exit-active {
    opacity: 0;
    transform: scale(1.5);
    transition: opacity 200ms, transform 400ms;
}

.css-clapgraph {
    transition: 1s ease;
    background: white;
    height: 100%;
    color: #f1ebe9;
}

.css-boxesterno {
    height: 100%;
}