.banner-container {
    width: 100%;
    overflow: hidden; /* Nasconde i messaggi che escono dal contenitore */
    background-color: transparent;
    color: white;
    padding: 10px 0;
    position: relative;
}

.banner-content {
    display: flex;
    gap: 50px; /* Spaziatura tra i messaggi */
    animation: scroll-left 120s linear infinite; /* Animazione continua */
    white-space: nowrap;
}

.banner-message {
    display: inline-block;
    font-size: 1.5rem;
}

.colored-message {
    color: yellow;
}

@keyframes scroll-left {
    0% {
        transform: translateX(100%); /* Partenza a destra */
    }
    100% {
        transform: translateX(-100%); /* Arrivo a sinistra */
    }
}
